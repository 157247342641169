<template>
  <div>
    <a-space>
      <a-button type="primary" @click="showLocal">上传本地文件</a-button>
      <a-button type="primary" @click="showGaoPaiYi">高拍仪上传</a-button>
    </a-space>

    <div class="img-box">
      <div v-for="img in data" :key="img.url" class="img-content">
        <img :src="img.url" @click="previewImg(img.url)">
        <div class="operate">
          <a
            class="custom-blue"
            @click="downloadFile(img.download_url, img.file_name)"
          >下载</a>
          <a class="custom-delete" @click="deleteImg(img.key_id)">删除</a>
        </div>
      </div>
    </div>

    <gao-pai-yi
      v-if="isShowGaoPaiYiModal"
      :visible.sync="isShowGaoPaiYiModal"
      :id="id"
      @completed="fetchData"
    />

    <local
      v-if="isShowLocalModal"
      :visible.sync="isShowLocalModal"
      :id="id"
      @completed="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :image.sync="imageUrl"
    />
  </div>
</template>

<script>
import { findOrderFiles, deleteOrderFile } from '@/api/order'
import PreviewImage from '@/components/PreviewImage'
export default {
  name: 'UploadFileIndex',
  components: {
    GaoPaiYi: () => import('@/views/orders/tenant/upload_file/GaoPaiYi'),
    Local: () => import('@/views/orders/tenant/upload_file/Local'),
    PreviewImage
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isShowGaoPaiYiModal: false,
      isShowLocalModal: false,
      data: [],
      imageUrl: '',
      previewVisible: false,
      attachments: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findOrderFiles(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    handleUploadImage(fileList) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          this.$warning({
            title: '上传照片错误',
            content: ''
          })
          return
        }
      }

      this.attachments = fileList
    },

    previewImg(url) {
      this.previewVisible = true
      this.imageUrl = url
    },

    deleteImg(keyId) {
      deleteOrderFile(this.id, { key_id: keyId }).then((res) => {
        if (res.code === 0) {
          this.fetchData()
        }
      })
    },

    showGaoPaiYi() {
      this.isShowGaoPaiYiModal = true
    },

    showLocal() {
      this.isShowLocalModal = true
    },

    downloadFile(url, fileName) {
      var x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
      }
      x.send()
    }
  }
}
</script>
<style lang="less" scoped>
.img-box {
  display: flex;
  flex-wrap: wrap;

  .img-content {
    margin: 5px;

    img {
      width: 100px;
      height: 100px;
    }

    .operate {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>

